/**
 * DO NOT use tailwind classes as this is used for both RGB and Sava
 */
import React from 'react';
import Cookies from 'universal-cookie';
import classnames from 'classnames';
import throttle from 'lodash.throttle';
import { getUrlParam } from '../../components/helpers';
import { get30Days, getXHoursFromNow } from '../../helpers/date-helpers';
import Paragraph from '../../components/basic/Paragraph';
import LinkHelper from './LinkHelper';
import { LOCALES } from '../../locales';
import { getNonEmptyStringOrFallback } from '../../helpers/string-helpers';

const PAGE_COUNTRY_REPLACER = '__PAGE_COUNTRY__';
const SITE_COUNTRY_REPLACER = '__SITE_COUNTRY__';

const fallbackHeading = 'This product is unavailable in your country';
const fallbackBackText = `Back to ${PAGE_COUNTRY_REPLACER} Home`;

const pageCountryRegex = new RegExp(PAGE_COUNTRY_REPLACER, 'ig');
const siteCountryRegex = new RegExp(SITE_COUNTRY_REPLACER, 'ig');

const hydrateTopbarContent = (pageCountry, siteCountry, content) => {
  return content
    .replace(pageCountryRegex, pageCountry)
    .replace(siteCountryRegex, siteCountry);
};

// eslint-disable-next-line quotes
const ReferralTopbarContent = `__FULL_NAME__ referred you! You'll get a <span className="topBar__underline">$100 Amazon Gift Card</span> if you sign up by __EXPIRATION_DATE__. <a href="/request-demo"><u>Request Demo →</u></a>`;
// eslint-disable-next-line quotes
const InternalRipplingPromoContent = `__FULL_NAME__ referred you! You're eligible for 3 months of Rippling free if you sign up by 12/31.  <a href="/request-demo"><u>Request Demo →</u></a>`;

const getHydratedPromoContent = ({ fullName, expirationDate, baseCopy }) => {
  const parsedExpirationDate = expirationDate.toLocaleDateString('en-us', {
    month: 'short',
    day: 'numeric',
  });

  return baseCopy
    .replace('__FULL_NAME__', fullName)
    .replace('__EXPIRATION_DATE__', parsedExpirationDate);
};

const TopBarInner = ({ activeTopbar }) => {
  let beforeBg = ' before-bg-plum-800';
  let afterBg = ' after-bg-yellow';
  if (activeTopbar._type === 'product-launch') {
    beforeBg = '';
    afterBg = ' after-bg-lightest-plum';
  } else if (activeTopbar._type === 'i18n-missing') {
    beforeBg = ' before-bg-yellow';
    afterBg = ' after-bg-light-red';
  }

  return (
    <>
      <div className="topBar__inner">
        {activeTopbar?._imageSrc && (
          <img className="" src={activeTopbar._imageSrc} alt="" />
        )}
        <Paragraph
          attributes={{
            className: activeTopbar._name === 'hris-switch' ? 'max-w-700' : '',
            innerContent:
              activeTopbar?._linkLabel && activeTopbar._withButton
                ? `${activeTopbar.message} <a href="${activeTopbar._buttonURL}"
                className="button-url"
                title={activeTopbar._linkLabel}
                >
                 ${activeTopbar._linkLabel}
                </a>`
                : `${activeTopbar.message} <a
                className="line-draw ${beforeBg}${afterBg} link-label"
                title={activeTopbar._linkLabel}
                >
               ${activeTopbar._linkLabel}
              </a>`,
          }}
        />
      </div>
    </>
  );
};

const TopBarOuter = ({ activeTopbar, children }) => {
  let bg = 'bg-yellow';
  if (activeTopbar._type === 'product-launch') {
    bg = 'bg-lightest-plum';
  } else if (activeTopbar._type === 'i18n-missing') {
    bg = 'bg-light-red';
  }

  return (
    <div
      className={classnames(
        'topBar-wrapper',
        activeTopbar._name === 'hris-switch' ? 'xsx-hidden' : null
      )}
    >
      <div className={classnames('topBar', bg)}>{children}</div>
    </div>
  );
};

class TopBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isClosed: false,
      hasMounted: false,
      _forceUpdateVal: false,
    };

    this.cookies = new Cookies();
    this.onClick = this.onClick.bind(this);
    this.addHeightAndHeaderOffset = this.addHeightAndHeaderOffset.bind(this);
    this.trottledAddHeightAndHeaderOffset = throttle(
      this.addHeightAndHeaderOffset,
      200
    );
    this.topbarIsCookiedClosed = this.topbarIsCookiedClosed.bind(this);
  }

  forceUpdate() {
    this.setState({ _forceUpdateVal: !this._forceUpdateVal });
  }

  setTopbarClosedCookie(topbarName) {
    return this.cookies.set(`topbar_state_${topbarName}`, 'closed', {
      expires: get30Days(),
    });
  }

  getTopbarClosedCookie(topbarName) {
    return this.cookies.get(`topbar_state_${topbarName}`);
  }

  topbarIsCookiedClosed(topbarName) {
    return this.getTopbarClosedCookie(topbarName) === 'closed';
  }

  /**
   * This is a forceful revalidation. Should only be used when things are posted accidentally.
   * This was initially done bc topbars cookies were set for 30 days.
   */
  revalidateTopbarsCookies() {
    const revalidationTS = this.props.topbars.forceRevalidationOnTS;
    if (!revalidationTS) {
      return;
    }

    // we want to create a new cookie any time this date is changed
    const revalidationCookieKey = `topbars_force_revalidation_${revalidationTS}`;

    // if its been done before, this will be true
    const hasBeenReset = this.cookies.get(revalidationCookieKey);

    // we want to avoid any additional work if this has been done already
    if (hasBeenReset) {
      return;
    }

    if (new Date().getTime() > revalidationTS) {
      this.cookies.remove('topbars_data', { path: '/' });
      this.cookies.set(revalidationCookieKey, true, { expires: get30Days() });
    }
  }

  async componentDidMount() {
    // this needs to run to clean any stale cookies (it was a bug...)
    this.revalidateTopbarsCookies();

    // Set Locales
    const cookiedSiteLocale = this.cookies.get('NEXT_LOCALE');
    // get locales
    this.siteLocale = cookiedSiteLocale || this.props.locale;

    window.addEventListener('resize', this.trottledAddHeightAndHeaderOffset);

    const shortCode = encodeURIComponent(getUrlParam('mbsy'));
    const that = this;

    const topbars = this.getTopbars();

    if (shortCode !== 'null') {
      try {
        const res = await fetch(
          `https://app.rippling.com/api/growth/get_referrer?short_code=${shortCode}`
        );
        const data = await res.json();

        if (data.name) {
          const thiryDaysFromNow = get30Days();
          const source = encodeURIComponent(getUrlParam('mbsy_source'));
          const campaignid = encodeURIComponent(getUrlParam('campaignid'));

          const ripplingReferrerUserCookie = {
            short_code: shortCode,
            source: source,
            campaignid: campaignid,
            referred_by: {
              id: encodeURIComponent(data.id),
            },
            expire_date: `${thiryDaysFromNow.getFullYear()}-${thiryDaysFromNow.getMonth()}-${thiryDaysFromNow.getDate()}`,
          };

          that.cookies.set('rippling_refer_user', ripplingReferrerUserCookie, {
            path: '/',
            expires: thiryDaysFromNow,
          });

          const shouldUseRipplingPromo =
            this.props.topbars?.enableRipplingReferralPromo && // feature flag
            data.isRipplingEmployee; // internal rippling employee check

          const referralContent = shouldUseRipplingPromo
            ? InternalRipplingPromoContent
            : ReferralTopbarContent;

          const firstName = data.name.full?.split(' ')?.[0];

          let message = `${firstName} referred you! You'll get a $250 Amazon Gift Card if you sign up by ${thiryDaysFromNow.toLocaleDateString(
            'en-us',
            { month: 'short', day: 'numeric' }
          )}.`;

          if (data.roles) {
            const isAlumni = data.roles?.find(
              ({ company, roleState }) =>
                company === '585c512df20db5063607e146' &&
                roleState === 'TERMINATED'
            );

            if (isAlumni) {
              message = `${firstName} referred you! You and your friend can get a $250 Gift Card if you book a qualifying demo. <a href="/request-demo"><u>Request Demo →</u></a>`;
            }
          }

          const referralTopbar = {
            message,
            type: 'referral',
            _name: 'referral',
            _linkLabel: 'Request Demo',
            _linkUrl: '/request-demo',
            status: that.topbarIsCookiedClosed('referral')
              ? 'closed'
              : 'active',
          };

          topbars.referral = referralTopbar;
          that.cookies.set('topbars_data', topbars, {
            path: '/',
            expires: getXHoursFromNow(1),
          });
          this.forceUpdate();
        }
      } catch (err) {
        console.error(err);
      }
    }

    const getEncodedURIParam = (param) => {
      const uriParam = getUrlParam(param);
      return uriParam ? encodeURIComponent(uriParam) : null;
    };

    const utmCampaign = getEncodedURIParam('utm_campaign')?.toLowerCase();
    const utmSource = getEncodedURIParam('utm_source')?.toLowerCase();

    const utmMedium = getEncodedURIParam('utm_medium')?.toLowerCase();
    const utmContent = getEncodedURIParam('utm_content')?.toLowerCase();

    const setFormCookies = () => {
      if (utmMedium === 'broker') {
        this.cookies.set('user_lead_source', 'Insurance Broker', {
          path: '/',
          expires: getXHoursFromNow(1),
        });
      }

      if (utmMedium === 'accountant') {
        this.cookies.set('user_lead_source', 'Accountant', {
          path: '/',
          expires: getXHoursFromNow(1),
        });
      }

      const whoReferredYou = {
        basis365: 'Basis 365',
        'aduro-advisors': 'Aduro Advisors',
        aeis: 'AEIS Advisors',
        akw: 'AKW Financial',
        alium: 'Alium',
        alliant: 'Alliant',
        bdo: 'BDO',
        bookkeeper360: 'Bookkeeper360',
        cfa: 'CFA Insurance',
        countsy: 'Countsy',
        finvisor: 'Finvisor',
        formationfinancial: 'Formation Financial',
        'kbi-benefits': 'KBI Consulting',
        'kruze-consulting': 'Kruze Consulting',
        launchways: 'Launchways',
        rfp: 'RFP',
        guideline: '',
        capitolbenefits: 'Capitol Benefits',
      };

      if (whoReferredYou[utmSource] || utmSource) {
        this.cookies.set(
          'who_referred_you',
          whoReferredYou[utmSource] || utmSource,
          { path: '/', expires: getXHoursFromNow(1) }
        );
      }
    };

    const campaignTopbars = this.props.topbars.data;

    let activeUtmCampaignTopbar = campaignTopbars.find((topbar) => {
      if (
        topbar._utm.campaign &&
        topbar._utm.campaign.toLowerCase() !== utmCampaign
      ) {
        return false;
      }
      if (
        topbar._utm.source &&
        topbar._utm.source.toLowerCase() !== utmSource
      ) {
        return false;
      }
      if (
        topbar._utm.medium &&
        topbar._utm.medium.toLowerCase() !== utmMedium
      ) {
        return false;
      }
      if (
        topbar._utm.content &&
        topbar._utm.content.toLowerCase() !== utmContent
      ) {
        return false;
      }

      setFormCookies();
      return true;
    });

    if (activeUtmCampaignTopbar) {
      const name =
        activeUtmCampaignTopbar._utm.source ||
        activeUtmCampaignTopbar._utm.campaign;

      activeUtmCampaignTopbar = {
        ...activeUtmCampaignTopbar,
        name,
        type: 'campaign',
        status: this.topbarIsCookiedClosed(name) ? 'closed' : 'active',
      };
      topbars[name] = activeUtmCampaignTopbar;

      this.cookies.set('topbars_data', topbars, {
        path: '/',
        expires: getXHoursFromNow(1),
      });
    } else {
      const activeTopbar = this.getActiveTopbar();

      if (!activeTopbar) {
        const generalTopbars = this.props.topbars.general;
        const audience =
          this.cookies.get('ExistingCustomer') === '1'
            ? 'customers'
            : 'nonCustomers';
        let firstQualifyingTopbar = generalTopbars?.find((topbar) => {
          let doesQualify =
            topbars[topbar._name]?.status !== 'closed' &&
            topbar._audience?.includes(audience);

          if (topbar._whitelist) {
            if (!topbar._whitelist.includes(window.location.pathname)) {
              doesQualify = false;
            }
          }

          if (topbar._params) {
            const allQueryParamsMet = topbar._params.every((param) => {
              if (param.expression === 'equals') {
                return getEncodedURIParam(param.key) === param.value;
              }
              if (param.expression === 'contains') {
                const regex = new RegExp(param.value);
                return regex.test(getEncodedURIParam(param.key));
              }
              return false;
            });

            if (!allQueryParamsMet) {
              doesQualify = false;
            }
          }

          return doesQualify;
        });

        if (firstQualifyingTopbar) {
          firstQualifyingTopbar = {
            ...firstQualifyingTopbar,
            type: 'general',
            status: this.topbarIsCookiedClosed(firstQualifyingTopbar._name)
              ? 'closed'
              : 'active',
          };

          topbars[firstQualifyingTopbar._name] = firstQualifyingTopbar;
          this.cookies.set('topbars_data', topbars, {
            path: '/',
            expires: getXHoursFromNow(1),
          });
        }
      }
    }
    this.setState({ hasMounted: true });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.topbarShouldShow() && !this.state.isClosed) {
      this.addHeightAndHeaderOffset();

      if (prevState.hasMounted !== this.state.hasMounted) {
        const activeTopbar = this.getActiveTopbar();

        window.dataLayer.push({
          event: 'topbar_view',
          topbar_name: activeTopbar._name,
        });

        const innerAnchor = document.querySelector('.topBar__message a');

        if (innerAnchor) {
          innerAnchor.addEventListener('click', () => {
            window.dataLayer.push({
              event: 'topbar_cta_click',
              topbar_name: activeTopbar._name,
              click_url: innerAnchor.getAttribute('href'),
            });
          });
        }
      }
    } else {
      const header = document.querySelector('.header__topbar');
      if (header) {
        header.style.top = 'auto';
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener(
      'resize',
      this.throttledAddHeightAndHeaderOffset
    );
  }

  getTopbars() {
    return this.cookies.get('topbars_data') || {};
  }

  shouldRenderI18nNotAvailTopbar() {
    const isi18nPage = !/^\/events|^\/blog/.test(window.location.pathname);
    if (!this.props.pageLocale || !isi18nPage) {
      return false;
    }
    // this should only show if the locale was defaulted (requested locale not available)
    // or if the page content locale is different than the cookied site locale
    const pageIsNotAllowedInLocales = this.props.pageLocaleDefaulted;
    return pageIsNotAllowedInLocales;
  }

  getActiveTopbar() {
    if (this.state.hasMounted && this.shouldRenderI18nNotAvailTopbar()) {
      const i18nMissingCmsContent = this.props.topbars?.i18nMissing;

      const { heading: cmsHeading, back: cmsBackText } =
        i18nMissingCmsContent || {};

      const siteLocaleToUse = getNonEmptyStringOrFallback(
        this.siteLocale,
        LOCALES.EN_US
      );

      const pageLocaleToUse = getNonEmptyStringOrFallback(
        this.props.pageLocale,
        LOCALES.EN_US
      );

      // site locale is the cookied user-set locale
      const siteCountry = siteLocaleToUse.split('-')[1];
      // page locale is the local being shown to the user
      const pageCountry = pageLocaleToUse.split('-')[1];

      // get content
      const heading = getNonEmptyStringOrFallback(cmsHeading, fallbackHeading);
      const backText = getNonEmptyStringOrFallback(
        cmsBackText,
        fallbackBackText
      );

      // get hydrated content
      const [hydratedHeading, hydratedBackText] = [
        heading,
        backText,
      ].map((content) =>
        hydrateTopbarContent(pageCountry, siteCountry, content)
      );

      return {
        _name: 'i18n-missing',
        _type: 'i18n-missing',
        message: hydratedHeading,
        _linkLabel: hydratedBackText,
        _linkUrl: '/',
        _imageSrc:
          'https://rippling.imgix.net/images/icon-warning-triangle-red.svg',
      };
    }

    const topbars = this.getTopbars();

    if (topbars) {
      const typeVals = { referral: 3, campaign: 2, general: 1 };

      const activeKeys = Object.keys(topbars).sort((a, b) => {
        const tb1 = topbars[a];
        const tb2 = topbars[b];

        return typeVals[tb2.type] - typeVals[tb1.type];
      });

      const activeKey = activeKeys.find((key) => {
        const topbarName = topbars[key]?._name;
        if (this.topbarIsCookiedClosed(topbarName)) {
          return false;
        }

        if (!topbars[key]?._whitelist) {
          return topbars[key]?.status === 'active';
        } else {
          if (typeof topbars[key]?._whitelist === 'string') {
            return (
              topbars[key]?.status === 'active' &&
              topbars[key]?._whitelist.includes(window.location.pathname)
            );
          }

          if (typeof topbars[key]?._whitelist === 'object') {
            const currentPath = window.location.pathname;
            const matchingSlug = topbars[key]?._whitelist.find((slug) =>
              slug.includes(currentPath)
            );
            if (matchingSlug) {
              return topbars[key]?.status === 'active';
            }
          }
        }
      });

      return topbars[activeKey];
    }
    return null;
  }

  topbarShouldShow() {
    const activeTopbar = this.getActiveTopbar();

    // @TODO removed hris-switch code here and in render. Build this functionality in WP.
    if (activeTopbar?._name === 'hris-switch') {
      if (
        [
          '/request-demo',
          '/accountants-demo-request',
          '/brokers-demo-request',
          '/peo-demo-request',
          '/quote',
          '/hris-switch',
        ].includes(window.location.pathname)
      ) {
        return false;
      }
    }

    return (
      activeTopbar &&
      this.topbarRegisteredInWp(activeTopbar) &&
      !this.dontRevealTopbar() &&
      this.topbarWhitelisted(activeTopbar)
    );
  }

  dontRevealTopbar() {
    return (
      /\/blog/.test(window.location.pathname) ||
      ['Paid', 'Form'].includes(this.props.pageType)
    );
  }

  topbarRegisteredInWp(activeTopbar) {
    if (
      activeTopbar &&
      activeTopbar.type &&
      activeTopbar._name &&
      activeTopbar.type === 'general'
    ) {
      return this.props.topbars[activeTopbar.type]?.find(
        (tb) => tb._name === activeTopbar._name
      );
    } else {
      return true;
    }
  }

  topbarWhitelisted(activeTopbar) {
    if (!activeTopbar._whitelist) {
      return true;
    }

    const { _whitelist } = activeTopbar;

    if (typeof _whitelist === 'string') {
      const whitelist = new RegExp(activeTopbar._whitelist);
      return whitelist.test(window.location.pathname);
    }

    if (typeof _whitelist === 'object') {
      const currentPath = window.location.pathname;
      const matchingSlug = activeTopbar._whitelist.find((slug) =>
        slug.includes(currentPath)
      );

      return Boolean(matchingSlug);
    }
  }

  addHeightAndHeaderOffset() {
    const topBarHeight = document.querySelector('.topBar')?.offsetHeight;
    const topbarWrapper = document.querySelector('.topBar-wrapper');
    if (topbarWrapper) {
      topbarWrapper.style.height = `${topBarHeight}px`;
    }

    const header = document.querySelector('.header__topbar');
    if (header) {
      header.style.top = `${topBarHeight}px`;
      header.classList.add('has-topBar');
    }
  }

  onClick() {
    const topbars = this.getTopbars();
    const topbarKeys = Object.keys(topbars);
    const activeTopbar = this.getActiveTopbar();

    window.dataLayer.push({
      event: 'topbar_close',
      topbar_name: activeTopbar._name,
    });

    for (let i = 0; i < topbarKeys.length; i++) {
      const key = topbarKeys[i];
      const topbar = topbars[key];

      if (topbar._name === activeTopbar._name) {
        topbar.status = 'closed';
        break;
      }
    }

    this.setTopbarClosedCookie(activeTopbar._name);
    this.cookies.set('topbars_data', topbars, {
      path: '/',
      expires: get30Days(),
    });
    this.setState({ isClosed: true });
  }

  render() {
    const showTopbar =
      this.state.hasMounted && this.topbarShouldShow() && !this.state.isClosed;

    if (!showTopbar) {
      return null;
    }

    const activeTopbar = this.getActiveTopbar();
    const isExternal = !!(
      activeTopbar._linkUrl && /http/.test(activeTopbar._linkUrl)
    );
    return (
      <TopBarOuter activeTopbar={activeTopbar}>
        {activeTopbar._linkUrl ? (
          <LinkHelper
            href={activeTopbar._linkUrl}
            locale={activeTopbar._type === 'i18n-missing' && this.siteLocale}
            className="topBar__message group line-card"
            target={isExternal ? '_blank' : '_self'}
          >
            <TopBarInner activeTopbar={activeTopbar} />
          </LinkHelper>
        ) : (
          <div className="topBar__message">
            <TopBarInner activeTopbar={activeTopbar} />
          </div>
        )}

        <button
          className="btn close -anchor"
          onClick={this.onClick}
          aria-label="Close Topbar"
        >
          <img
            src="https://rippling.imgix.net/images/icon-close-plum.svg"
            alt="Close Topbar"
          />
        </button>
      </TopBarOuter>
    );
  }
}

export default TopBar;
